.flex-container {
  display: flex;
}

.main-wrapper {
  display: inline-flex;
  margin-top: 40px;
  padding-right: 25px;
  height: 22vh;
  width: 22vw;
  min-width: 150px;
  background: #fff;
  overflow-y: scroll;
}

.values {
  padding: 0px;
  margin-left: 0px;
}

.value {
  margin: 8.5px;
  list-style: none;
  text-align: left;
  display: flex;
  flex-direction: row;
  font-size: small;
}

/* Customize website's scrollbar like Mac OS
Not supports in Firefox and IE */

/* total width */
.main-wrapper ::-webkit-scrollbar {
  background-color: #fff;
  width: 6px;
}

/* background of the scrollbar except button or resizer */
.main-wrapper ::-webkit-scrollbar-track {
  background-color: #fff;
}
.main-wrapper ::-webkit-scrollbar-track:hover {
  background-color: #f4f4f4;
}

/* scrollbar itself */
.main-wrapper ::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border: 5px solid #fff;
}
.main-wrapper ::-webkit-scrollbar-thumb:hover {
  background-color: #a0a0a5;
  border: 4px solid #f4f4f4;
}

/* set button(top and bottom of the scrollbar) */
.main-wrapper ::-webkit-scrollbar-button {
  display: none;
}

.overflow {
  min-height: 1vh;
  display: flex;
}

.listItemStyle {
  color: #333;
  list-style-type: none;
  text-align: left;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 8px;
}

.legendBox {
  margin-left: 8px;
  width: 20px;
  height: 20px;
}

.brandText {
  width: max-content !important;
  font-size: small;
}

.firstTextCenter {
  display: block;
  font-size: x-large;
  font-weight: bold;
  color: #5432dc;
  margin-bottom: -9px;
}

.secondTextCenter {
  font-weight: bold;
  font-size: small;
}

.dounutTextCenter {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 185px;
}

.donutContainer {
  position: relative;
}
