.chart {
  z-index: 10;
}

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 500px;
  border: solid 0.5px #d7d6d6;
  margin-top: 5px;
}

.spinnerBorder {
  width: 4rem;
  height: 4rem;
}

.gridCellIcon {
  display: inline-block;
  vertical-align: top;
  width: 12%;

  svg {
    max-width: 100%;
  }
}

.gridCell {
  font-size: 0.8rem;
  display: inline-block;
  width: 88%;

  > span {
    display: inline-block;
    text-align: right;
    width: 45%;

    &:nth-of-type(2) {
      text-align: center;
      width: 10%;
    }

    &:last-of-type {
      text-align: left;
    }
  }
}

.rankCellIcon {
  display: inline-block;
  vertical-align: top;
  width: 24%;

  svg {
    max-width: 100%;
  }
}

.legendContainer {
  display: inline-flex;
  margin-top: 40px;
  padding-right: 25px;
  height: 22vh;
  width: 22vw;
  min-width: 150px;
  background: #fff;
  overflow-y: scroll;
}

.horizontalBar {
  height: 600px;
}
